<template>
    <v-layout justify-center row>
        <v-flex xs12 sm8 md6>
            <terms-of-use backColor=""></terms-of-use>
        </v-flex>
    </v-layout>
</template>

<script>
import store from '@/store'
import TermsOfUse from '@/components/TermsOfUse'
import { SideBtnType } from '@/store'
import i18n from '@/i18n'

export default {
    name: 'TermsOfUsePage',
    components: { TermsOfUse },
    activated() {
        store.commit('setTitle', i18n.t('termsOfUse.term-use'))
        store.commit('setSideBtnType', SideBtnType.Back)
    }
}
</script>

<style scoped>
</style>